


























































import { Component, Vue } from 'vue-property-decorator'
import {
  confirmDialog,
  successMsg,
  errorMsg
} from '@/utils'
import { AppModule, DeviceType } from '@/store/modules/app'
import {
  deleteEmail,
  getEmail, sendEmail
} from '@/api/emails'
import Sticky from '@/components/Sticky/index.vue'

@Component({
  name: 'InternalMailList',
  components: {
    Sticky
  }
})

export default class extends Vue {
  private loading = false
  private skeletonLoader = false
  private email: any = {}

  created() {
    if (this.$route.params.id) {
      this.getItem(this.$route.params.id)
    }
  }

  get isMobile() {
    return AppModule.device === DeviceType.Mobile
  }

  private async deleteItem() {
    try {
      const [data] = await confirmDialog('actions.apiDelete')
      if (!data) return
      await deleteEmail({
        id: this.email.id
      })
      await successMsg('actions.apiDeleteSuccess')
    } catch (err) {
      await errorMsg('api.serverError')
    }
  }

  private async getItem(id: string) {
    try {
      this.skeletonLoader = true
      const { data } = await getEmail({ id })
      this.email = data
    } catch (err) {
      await errorMsg('api.entityNotFound')
    } finally {
      this.skeletonLoader = false
    }
  }

  private sendEmail() {
    sendEmail({ id: this.email.id })
  }
}
